import { isEmpty } from './index'

export const required = value => (value && value.length ? true : 'This field is required')

export const simpleRequired = value => (value ? true : 'This field is required')

export const isProxyUrl = value => {
  const proxyURLReg = /(http|https|socks4|socks4a|socks5|socks5h){1,1}?(?::\/\/)?([\w-]+(?::[\w-]+)?@)?((?:[-\w.]+))(?::(\d{1,5}))/

  return proxyURLReg.test(value) ? true : 'Incorrect Proxy URL'
}

export const emailValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (Array.isArray(value)) {
    return value.every(val => re.test(String(val)))
  }

  return re.test(String(value)) || 'The Email field must be a valid email'
}

export const passwordValidator = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{6,}$/
  /* eslint-enable no-useless-escape */
  let passwords = []
  if (typeof password === 'string') {
    passwords = password.trim().split('\n')
  }
  let validPassword = true
  passwords.forEach(item => {
    if (validPassword) {
      validPassword = regExp.test(item)
    }
  })

  return (
    // eslint-disable-next-line operator-linebreak
    validPassword ||
    'Password must be at least 6 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number'
  )
}
export const hexValidator = color => {
  /* eslint-disable no-useless-escape */
  const regExp = /^#([A-F0-9]{3}|[A-F0-9]{6})$/i
  /* eslint-enable no-useless-escape */
  const colors = color.trim().split('\n')
  let validColor = true
  colors.forEach(item => {
    if (validColor) {
      validColor = regExp.test(item)
    }
  })

  return (
    // eslint-disable-next-line operator-linebreak
    validColor ||
    'Value must be in hex format'
  )
}

export const confirmedValidator = (value, target) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  value === target || 'The Confirm Password field confirmation does not match'

export const between = (value, min, max) => () => {
  const valueAsNumber = Number(value)

  return (Number(min) <= valueAsNumber && Number(max) >= valueAsNumber) || `Enter number between ${min} and ${max}`
}

export const integerValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  if (Array.isArray(value)) {
    return value.every(val => /^-?[0-9]+$/.test(String(val)))
  }

  return /^-?[0-9]+$/.test(String(value)) || 'This field must be an integer'
}

export const regexValidator = (value, regex) => {
  if (isEmpty(value)) {
    return true
  }

  let regeX = regex
  if (typeof regeX === 'string') {
    regeX = new RegExp(regeX)
  }

  if (Array.isArray(value)) {
    return value.every(val => regexValidator(val, { regeX }))
  }

  return regeX.test(String(value)) || 'The Regex field format is invalid'
}

export const alphaValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  // const valueAsString = String(value)

  return /^[A-Z]*$/i.test(String(value)) || 'The Alpha field may only contain alphabetic characters'
}

export const urlValidator = value => {
  if (value === undefined || value === null || value.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/

  return re.test(value) || 'URL is invalid'
}

export const lengthValidator = (value, length) => {
  if (isEmpty(value)) {
    return true
  }

  return value.length === length || `The Min Character field must be at least ${length} characters`
}
export const alphaDashValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  const valueAsString = String(value)

  return /^[0-9A-Z_-]*$/i.test(valueAsString) || 'All Character is not valid'
}
export const maximumValue = max => value => (value <= max ? true : `Value must be less than ${max}`)
export const maxMultiplyValue = (multiplier, max) => value => ((multiplier * value) <= max ? true : `Result of multiplying Account delay by Batch size  should be less than ${max}`)
export const isTrue = value => !!value
export const isMore = more => value => (value < more ? `Value must be more than ${more}` : true)

export const isNotEmptyString = str => (str && str.length && str?.trim() ? true : 'This field is required')

export const isURL = url => {
  try {
    const _url = new URL(url)

    return !!_url
  } catch (error) {
    return 'Value must be URL'
  }
}

export const stringLengthValidation = (min, max) => value => {
  if (min) {
    return (value.length < min || value.length > max ? `Must be between ${min} and ${max} in length` : true)
  }

  return (value.length > max ? `field must be less than ${max} in length` : true)
}

export const passwordConfirmValidation = passwordConfirm => value => (value === passwordConfirm ? true : 'Passwords are not the same')
